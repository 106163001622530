.history-list__item-body {
  padding: 15px 0;
/*_________PRODUCTS_BLOCK________*/
/*_________PERSONAL_PROPOSAL_BLOCK________*/
/*_________COLLECTING_BLOCK________*/
/*_________PAYMENT_TYPE_BLOCK________*/
}
.history-list__item-body .products {
  padding: 11px 0 35px;
  border-bottom: 1px solid #e5e5e4;
  border-bottom-style: dashed;
}
@media (max-width: 768px) {
  .history-list__item-body .products {
    padding: 0 0 24px;
  }
}
.history-list__item-body .products .check-number {
  font-size: 32px;
  font-weight: 600;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
}
@media (max-width: 500px) {
  .history-list__item-body .products .check-number {
    font-size: 18px;
  }
}
.history-list__item-body .products .check-number a {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
}
@media (max-width: 500px) {
  .history-list__item-body .products .check-number a {
    margin-left: auto;
  }
}
.history-list__item-body .products .check-number .button_e-check {
  border-radius: 30px;
  width: 37px;
  height: 37px;
  padding: 0;
  margin-left: 16px;
  padding: 7px 11px;
  -o-text-overflow: clip;
  text-overflow: clip;
  line-height: normal;
}
.history-list__item-body .products .check-number .button_e-check:not(:disabled):hover,
.history-list__item-body .products .check-number .button_e-check:not(:disabled):focus {
  background: #79b928;
  width: 190px;
}
@media (max-width: 500px) {
  .history-list__item-body .products .check-number .button_e-check:not(:disabled):hover,
  .history-list__item-body .products .check-number .button_e-check:not(:disabled):focus {
    width: 37px;
  }
}
.history-list__item-body .products .check-number .button_e-check:not(:disabled):hover {
  background: #6caf21;
}
.history-list__item-body .products .check-number .button_e-check:not(:disabled):focus {
  background: #60a51b;
}
@media (max-width: 1200px) {
  .history-list__item-body .products .check-number .button_e-check {
    width: 190px;
  }
}
@media (max-width: 1100px) {
  .history-list__item-body .products .check-number .button_e-check {
    width: 37px;
  }
}
@media (max-width: 768px) {
  .history-list__item-body .products .check-number .button_e-check {
    width: 190px;
    margin-right: 12px;
  }
}
@media (max-width: 500px) {
  .history-list__item-body .products .check-number .button_e-check {
    width: 37px;
  }
  .history-list__item-body .products .check-number .button_e-check:hover,
  .history-list__item-body .products .check-number .button_e-check:focus {
    width: 37px;
  }
}
.history-list__item-body .products .check-number .button_e-check .icon-receipt {
  margin-right: 11px;
}
.history-list__item-body .products .check-number .button_e-check span {
  display: inline-block;
  padding-top: 2px;
}
.history-list__item-body .products .check-number .buttton_share {
  background: transparent;
  height: 37px;
  width: 190px;
  border-radius: 30px;
  font-weight: 400;
  line-height: normal;
  margin-left: auto;
  margin-right: 20px;
}
.history-list__item-body .products .check-number .buttton_share:not(:disabled):hover,
.history-list__item-body .products .check-number .buttton_share:not(:disabled):focus {
  border-color: #79b928;
  color: #79b928;
}
.history-list__item-body .products .check-number .buttton_share:not(:disabled):hover {
  background: #e3eed7;
}
.history-list__item-body .products .check-number .buttton_share:not(:disabled):focus {
  background: #d9e8c9;
}
@media (max-width: 1200px) {
  .history-list__item-body .products .check-number .buttton_share {
    width: 37px;
    min-width: 37px;
    padding: 0 10px;
  }
}
@media (max-width: 1100px) {
  .history-list__item-body .products .check-number .buttton_share {
    width: 190px;
    padding: 7px 19px 8px;
  }
}
@media (max-width: 768px) {
  .history-list__item-body .products .check-number .buttton_share {
    margin-right: 0;
    width: 37px;
    min-width: 37px;
    padding: 0 10px;
  }
}
@media (max-width: 500px) {
  .history-list__item-body .products .check-number .buttton_share {
    margin-left: 0;
  }
}
.history-list__item-body .products .check-number .buttton_share .icon-share {
  margin-right: 10px;
}
.history-list__item-body .products .check-number .buttton_share span {
  display: inline-block;
  padding-top: 2px;
}
.history-list__item-body .products .product-list {
  margin: 0;
  padding: 0;
}
.history-list__item-body .products .product-list .product-list-item {
  margin-top: 20px;
  padding: 0;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 16px;
  color: #333;
}
@media (max-width: 768px) {
  .history-list__item-body .products .product-list .product-list-item {
    margin-top: 15px;
    font-size: 12px;
  }
}
.history-list__item-body .products .product-list .product-list-item .product-title {
  -webkit-box-flex: 6;
  -moz-box-flex: 6;
  -o-box-flex: 6;
  box-flex: 6;
  -webkit-flex: 6 6 50%;
  -ms-flex: 6 6 50%;
  flex: 6 6 50%;
}
.history-list__item-body .products .product-list .product-list-item .product-title .product-count--mobile {
  display: none;
}
@media (max-width: 768px) {
  .history-list__item-body .products .product-list .product-list-item .product-title {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    box-flex: 1;
    -webkit-flex: 0 1 50%;
    -ms-flex: 0 1 50%;
    flex: 0 1 50%;
  }
  .history-list__item-body .products .product-list .product-list-item .product-title .product-count--mobile {
    display: inline;
  }
}
.history-list__item-body .products .product-list .product-list-item .product-count {
  min-width: 150px;
  text-align: right;
}
@media (max-width: 768px) {
  .history-list__item-body .products .product-list .product-list-item .product-count {
    display: none;
  }
}
.history-list__item-body .products .product-list .product-list-item .product-cost {
  min-width: 140px;
  margin-right: 20px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: "Lato";
  text-align: right;
}
@media (max-width: 768px) {
  .history-list__item-body .products .product-list .product-list-item .product-cost {
    min-width: 60px;
    margin-right: 0;
  }
}
.history-list__item-body .products .product-list .product-list-item.to-moneybox {
  font-size: 18px;
  color: #333;
}
@media (max-width: 768px) {
  .history-list__item-body .products .product-list .product-list-item.to-moneybox {
    font-size: 13px;
  }
}
.history-list__item-body .products .product-list .product-list-item.to-moneybox .product-title {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.history-list__item-body .products .product-list .product-list-item.to-moneybox .product-cost {
  margin-right: 20px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  text-align: right;
}
@media (max-width: 768px) {
  .history-list__item-body .products .product-list .product-list-item.to-moneybox .product-cost {
    margin-right: 0;
  }
}
.history-list__item-body .personal-proposals {
  padding: 25px 0;
  border-bottom: 1px solid #e5e5e4;
}
@media (max-width: 768px) {
  .history-list__item-body .personal-proposals {
    padding: 15px 0;
  }
}
.history-list__item-body .personal-proposals .discount {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
.history-list__item-body .personal-proposals .discount .discount-title {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .history-list__item-body .personal-proposals .discount .discount-title {
    font-size: 13px;
  }
}
.history-list__item-body .personal-proposals .discount .discount-sum {
  margin-right: 20px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-size: 18px;
  text-align: right;
}
@media (max-width: 768px) {
  .history-list__item-body .personal-proposals .discount .discount-sum {
    margin-right: 0;
    font-size: 13px;
  }
}
.history-list__item-body .personal-proposals .personal-proposal-caption {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .history-list__item-body .personal-proposals .personal-proposal-caption {
    margin-top: 5px;
    font-size: 13px;
  }
}
.history-list__item-body .personal-proposals .personal-proposal-list {
  height: 170px;
  padding: 30px 30px 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
@media (max-width: 768px) {
  .history-list__item-body .personal-proposals .personal-proposal-list {
    height: 112px;
    padding: 20px 5px 0;
  }
}
.history-list__item-body .personal-proposals .personal-proposal-list .personal-proposal-list-item {
  height: 115px;
  width: 340px;
  margin-right: 30px;
  padding: 15px;
  background: #fff;
  -webkit-box-shadow: 0 0 12px rgba(0,0,0,0.25);
  box-shadow: 0 0 12px rgba(0,0,0,0.25);
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  white-space: initial;
}
.history-list__item-body .personal-proposals .personal-proposal-list .personal-proposal-list-item:last-child {
  margin-right: 0;
}
@media (max-width: 768px) {
  .history-list__item-body .personal-proposals .personal-proposal-list .personal-proposal-list-item {
    height: 70px;
    width: auto;
    margin-right: 15px;
    -webkit-box-shadow: 0 0 6px rgba(0,0,0,0.2);
    box-shadow: 0 0 6px rgba(0,0,0,0.2);
  }
}
.history-list__item-body .personal-proposals .personal-proposal-list .personal-proposal-list-item .list-item--inner {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.history-list__item-body .personal-proposals .personal-proposal-list .personal-proposal-list-item .personal-proposal-value {
  padding-right: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  font-family: "Lato";
  font-size: 60px;
}
@media (max-width: 768px) {
  .history-list__item-body .personal-proposals .personal-proposal-list .personal-proposal-list-item .personal-proposal-value {
    padding-right: 10px;
    font-size: 36px;
  }
}
.history-list__item-body .personal-proposals .personal-proposal-list .personal-proposal-list-item .personal-proposal-value .proposal-value-sign {
  color: #f00;
  margin-right: 5px;
}
@media (max-width: 768px) {
  .history-list__item-body .personal-proposals .personal-proposal-list .personal-proposal-list-item .personal-proposal-value .proposal-value-sign {
    margin-right: 3px;
  }
}
.history-list__item-body .personal-proposals .personal-proposal-list .personal-proposal-list-item .personal-proposal-value .value-wrapper .proposal-value {
  display: block;
  font-family: "Lato";
}
.history-list__item-body .personal-proposals .personal-proposal-list .personal-proposal-list-item .personal-proposal-value .value-wrapper .proposal-value-unit {
  margin-top: -15px;
  display: block;
  font-size: 18px;
}
@media (max-width: 768px) {
  .history-list__item-body .personal-proposals .personal-proposal-list .personal-proposal-list-item .personal-proposal-value .value-wrapper .proposal-value-unit {
    margin-top: -10px;
    font-size: 14px;
  }
}
.history-list__item-body .personal-proposals .personal-proposal-list .personal-proposal-list-item .personal-proposal-title {
  padding-top: 10px;
  font-size: 18px;
}
@media (max-width: 768px) {
  .history-list__item-body .personal-proposals .personal-proposal-list .personal-proposal-list-item .personal-proposal-title {
    padding-top: 6px;
    font-size: 14px;
  }
  .history-list__item-body .personal-proposals .personal-proposal-list .personal-proposal-list-item .personal-proposal-title span {
    display: block;
    max-height: 36px;
    overflow: hidden;
  }
}
.history-list__item-body .personal-proposals .personal-proposal-list .personal-proposal-list-item .personal-proposal-title hr {
  margin: 5px 0;
}
.history-list__item-body .collecting {
  padding: 25px 0;
  border-bottom: 1px solid #ccc;
}
@media (max-width: 768px) {
  .history-list__item-body .collecting {
    padding: 15px 0;
  }
}
.history-list__item-body .collecting .collecting-caption {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
}
.history-list__item-body .collecting .collecting-caption span {
  color: #79b928;
}
@media (max-width: 768px) {
  .history-list__item-body .collecting .collecting-caption {
    margin-top: 5px;
    font-size: 13px;
  }
}
.history-list__item-body .collecting .collecting-caption .icon-chevron-right {
  margin-left: 8px;
}
.history-list__item-body .collecting .collecting-list {
  background-color: transparent;
  height: 175px;
  padding: 30px 30px 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
@media (max-width: 768px) {
  .history-list__item-body .collecting .collecting-list {
    height: 127px;
    padding: 20px 5px 0;
  }
}
.history-list__item-body .collecting .collecting-list .collecting-list-item {
  height: 120px;
  width: 190px;
  margin-right: 30px;
  padding: 15px;
  background: #fff;
  -webkit-box-shadow: 0 0 12px rgba(0,0,0,0.25);
  box-shadow: 0 0 12px rgba(0,0,0,0.25);
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -moz-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  white-space: initial;
}
.history-list__item-body .collecting .collecting-list .collecting-list-item:last-child {
  margin-right: 0;
}
@media (max-width: 768px) {
  .history-list__item-body .collecting .collecting-list .collecting-list-item {
    height: 85px;
    width: 300px;
    margin-right: 15px;
    -webkit-box-shadow: 0 0 6px rgba(0,0,0,0.2);
    box-shadow: 0 0 6px rgba(0,0,0,0.2);
  }
}
.history-list__item-body .collecting .collecting-list .collecting-list-item .collecting-title {
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
}
.history-list__item-body .collecting .collecting-list .collecting-list-item .collecting-title span {
  display: block;
  max-height: 32px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .history-list__item-body .collecting .collecting-list .collecting-list-item .collecting-title {
    font-size: 14px;
  }
  .history-list__item-body .collecting .collecting-list .collecting-list-item .collecting-title span {
    max-height: 14px;
  }
}
.history-list__item-body .collecting .collecting-list .collecting-list-item .collecting-title hr {
  margin: 5px 0;
}
.history-list__item-body .collecting .collecting-list .collecting-list-item .collecting-value {
  padding-top: 6px;
  font-size: 16px;
  line-height: 1;
}
.history-list__item-body .collecting .collecting-list .collecting-list-item .collecting-value span {
  display: block;
  max-height: 16px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .history-list__item-body .collecting .collecting-list .collecting-list-item .collecting-value {
    font-size: 12px;
  }
  .history-list__item-body .collecting .collecting-list .collecting-list-item .collecting-value span {
    max-height: 12px;
  }
}
.history-list__item-body .collecting .collecting-list .collecting-list-item .collecting-status {
  margin-top: auto;
  padding-top: 10px;
  font-size: 12px;
  line-height: 18px;
  color: #333;
}
@media (max-width: 768px) {
  .history-list__item-body .collecting .collecting-list .collecting-list-item .collecting-status {
    padding-top: 6px;
    font-size: 12px;
    line-height: 1;
    text-transform: lowercase;
    color: #a2a2a2;
  }
}
.history-list__item-body .payment-type {
  padding: 25px 0 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
@media (max-width: 768px) {
  .history-list__item-body .payment-type {
    padding: 15px 0 0;
  }
}
.history-list__item-body .payment-type .payment-type-title {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .history-list__item-body .payment-type .payment-type-title {
    font-size: 13px;
  }
}
.history-list__item-body .payment-type .payment-type-value {
  margin-right: 20px;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-size: 18px;
  text-align: right;
}
@media (max-width: 768px) {
  .history-list__item-body .payment-type .payment-type-value {
    margin-right: 0;
    font-size: 13px;
  }
}
.collecting-caption .icon-holder,
.personal-proposal-caption .icon-holder,
.discount-title .icon-holder,
.payment-type-title .icon-holder {
  width: 30px;
  display: inline-block;
  text-align: left;
}
.collecting-caption .icon-holder svg,
.personal-proposal-caption .icon-holder svg,
.discount-title .icon-holder svg,
.payment-type-title .icon-holder svg {
  color: #79b928;
}
@media (max-width: 768px) {
  .collecting-caption .icon-holder,
  .personal-proposal-caption .icon-holder,
  .discount-title .icon-holder,
  .payment-type-title .icon-holder {
    width: 20px;
  }
}
@media (max-width: 768px) {
  .icon-holder {
    margin-right: 12px;
  }
}
/*# sourceMappingURL=src/js/components/HistoryList/HistoryCheck/HistoryCheck.css.map */